import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: -180px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: -350px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 786px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -300px;
      margin-left: -460px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: -200px;
      margin-left: -270px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: -330px;
      margin-left: -380px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: -330px;
      margin-left: -440px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgHombreVolando = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    position: absolute;
    width: 200px;
    height: auto;
    margin-top: 195px;
    margin-left: 158px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: absolute;
    width: 320px;
    height: auto;
    margin-top: 195px;
    margin-left: 658px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    position: absolute;
    width: 340px;
    height: auto;
    margin-top: 195px;
    margin-left: 658px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    position: absolute;
    width: 180px;
    height: auto;
    left: 50%;
    margin-top: 330px;
    margin-left: 310px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    position: absolute;
    width: 270px;
    height: auto;
    left: 50%;
    margin-top: 400px;
    margin-left: 350px;
  }
  @media(min-width: 1920px){
    position: absolute;
    width: 330px;
    height: auto;
    left: 50%;
    margin-top: 400px;
    margin-left: 550px;
  }
`
export const ImgHombreVolando = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgMujerBailando = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 80px;
    height: auto;
    margin-top: -300px;
    margin-left: -29px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 160px;
    height: auto;
    margin-top: -300px;
    margin-left: -29px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 200px;
    height: auto;
    margin-top: -360px;
    margin-left: -29px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 120px;
    height: auto;
    margin-top: -400px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 150px;
    height: auto;
    margin-top: -500px;
  }
  @media(min-width: 1920px){
    width: 180px;
    height: auto;
    margin-top: -600px;
  }
`
export const ImgMujerBailando = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreParado = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 70px;
    height: auto;
    margin-top: -300px;
    margin-left: -205px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100px;
    height: auto;
    margin-top: -690px;
    margin-left: -475px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 170px;
    height: auto;
    margin-top: -815px;
    margin-left: -550px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 70px;
    height: auto;
    margin-top: -370px;
    margin-left: -300px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 110px;
    height: auto;
    margin-top: -472px;
    margin-left: -450px;
  }
  @media(min-width: 1920px){
    width: 135px;
    height: auto;
    margin-top: -520px;
    margin-left: -500px;
  }
`
export const ImgHombreParado = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreFuego = styled.div`
  position: absolute;
  animation: image-generated 5s alternate infinite;
  z-index: 99;

  @keyframes image-generated
  {
    0% {
      transform: scaleX(0);
    }
    10% {
      transform: scaleX(-1);
    }
    15% {
      transform: scaleX(0);
    }
    20% {
      transform: scaleX(-1);
    }
    23% {
      transform: scaleX(0);
    }
    30% {
      transform: scaleX(-1);
    }
    40% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(-1);
    }
    60% {
      transform: scaleX(0);
    }
    70% {
      transform: scaleX(-1);
    }
    80% {
      transform: scaleX(0);
    }
    90% {
      transform: scaleX(-1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 70px;
    height: auto;
    margin-top: -300px;
    margin-left: -205px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100px;
    height: auto;
    margin-top: -690px;
    margin-left: -475px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 170px;
    height: auto;
    margin-top: -815px;
    margin-left: -550px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 170px;
    height: auto;
    margin-top: 370px;
    margin-left: -300px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 110px;
    height: auto;
    margin-top: -472px;
    margin-left: -450px;
  }
  @media(min-width: 1920px){
    width: 135px;
    height: auto;
    margin-top: -520px;
    margin-left: -500px;
  }
`
export const ImgHombreFuego = styled.img`
  width: 100%;
  height: 100%;
`
