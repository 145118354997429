import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgHombreVolando, ImgHombreVolando, ContImgMujerBailando, ImgMujerBailando, ContImgHombreParado, ImgHombreParado, ContImgHombreFuego, ImgHombreFuego} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {ContImgLeft, ContImgRight, GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import useSound from 'use-sound';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import imgHombreVolando from '../../../images/obras/obra-13/hombre-volando.png';
import imgMujerBailando from '../../../images/obras/obra-13/mujer-bailando.png';
import imgHombreParado from '../../../images/obras/obra-13/hombre-parado.png';
import imgHombreFuego from '../../../images/obras/obra-13/hombre-fuego.png';
import soundHombrevolando from '../../../images/obras/obra-13/hombre-volando.wav';
import soundFire from '../../../images/obras/obra-13/fire.mp3';
import imgLeft from '../../../images/obras/obra-12/obra.jpeg';
import imgRight from '../../../images/obras/obra-14/obra.jpeg';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 14,
  bottom: null,
  left: 12,
};

const Page13Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMenFly, setInitAnimatedMenFly] = useState(false);
  const [initAnimatedMujerBailando, setInitAnimatedMujerBailando] = useState(false);
  const [initAnimatedHombreParado, setInitAnimatedHombreParado] = useState(false);
  const [initAnimatedHombreFuego, setInitAnimatedHombreFuego] = useState(false);
  const [initSoundMenFly, setInitSoundMenFly] = useState(false);
  const [initSoundFire, setInitSoundFire] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-13/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };
  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>Pequeño vértigo.</div>
            <div>Mi yo cotidiano</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnReLaunchAll = () => {
    fnReLaunchMenFly(0);
    fnReLaunchMujerBailando(0);
    fnReLaunchHombreParado(0);
    fnReLaunchHombreFuego(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchMenFly = (noReplay = 1) => {
    setInitAnimatedMenFly(false);
    setInitSoundMenFly(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMenFly(true);
      }, 1000);
    }
  };
  const fnReLaunchMujerBailando = (noReplay = 1) => {
    setInitAnimatedMujerBailando(false);
    // setInitSoundMujerBailando(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMujerBailando(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreParado = (noReplay = 1) => {
    setInitAnimatedHombreParado(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreParado(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreFuego = (noReplay = 1) => {
    setInitAnimatedHombreFuego(false);
    setInitSoundFire(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreFuego(true);
      }, 1000);
    }
  };

  const fnAnimateMenFly = () => {
    return (
      <ContImgHombreVolando className={'img-animated'} onClick={() => fnReLaunchMenFly()}>
        {
          initAnimatedMenFly &&
          <ImgHombreVolando
            id={'animate-hombre-volando'}
            className={'animate-hombre-volando'}
            src={imgHombreVolando}
            alt="Hombre"
          />
        }
      </ContImgHombreVolando>
    )
  }
  const fnAnimateMujerBailando = () => {
    return (
      <ContImgMujerBailando className={'img-animated'} onClick={() => fnReLaunchMujerBailando()}>
        {
          initAnimatedMujerBailando &&
          <ImgMujerBailando
            id={'animate-mujer-cayendo'}
            className={'animate-mujer-cayendo'}
            src={imgMujerBailando}
            alt="Mujer Bailando"
          />
        }
      </ContImgMujerBailando>
    )
  }
  const fnAnimateHombreParado = () => {
    return (
      <ContImgHombreParado className={'img-animated'} onClick={() => fnReLaunchHombreParado()}>
        {
          initAnimatedHombreParado &&
          <ImgHombreParado
            id={'animate-hombre-parado'}
            className={'animate-hombre-parado'}
            src={imgHombreParado}
            alt="Hombre Parado"
          />
        }
      </ContImgHombreParado>
    )
  }
  const fnAnimateHombreFuego = () => {
    return (
      <ContImgHombreFuego className={'img-animated'} onClick={() => fnReLaunchHombreFuego()}>
        {
          initAnimatedHombreFuego &&
          <ImgHombreFuego
            id={'animate-hombre-fuego'}
            className={'animate-hombre-fuego'}
            src={imgHombreFuego}
            alt="Hombre Fuego"
          />
        }
      </ContImgHombreFuego>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  const [playHombreVolando, { stopHombreVolando }] = useSound(
    soundHombrevolando,
    { volume: 0.3 }
  );
  const [playFire, { stopFire }] = useSound(
    soundFire,
    { volume: 0.3 }
  );

  useEffect(() => {

    setTimeout(function (){
      setInitAnimatedMenFly(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedMujerBailando(true);
    }, 6000);

    setTimeout(function (){
      setInitAnimatedHombreParado(true);
    }, 8000);
    setTimeout(function (){
      setInitAnimatedHombreFuego(true);
    }, 10000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 13000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);

    const winWidth = window.innerWidth;
    let arPosMenFly = {};
    let arPosMujerBailando = {};

    if(winWidth < 767){
      arPosMenFly = {x: -45, y: -190}
      arPosMujerBailando = {y: 240}
    } else if(winWidth < 1023){
      arPosMenFly = {x: -250, y: -250}
      arPosMujerBailando = {y: 250}
    } else if(winWidth < 1215){
      arPosMenFly = {x: -200, y: -330}
      arPosMujerBailando = {y: 300}
    } else if(winWidth < 1407){
      arPosMenFly = {x: -300, y: -300}
      arPosMujerBailando = {y: 400}
    } else if(winWidth < 1919){
      arPosMenFly = {x: -400, y: -400}
      arPosMujerBailando = {y: 500}
    } else {
      arPosMenFly = {x: -600, y: -450}
      arPosMujerBailando = {y: 600}
    }

    if(initAnimatedMenFly){
      gsap.to(".animate-hombre-volando", {
        x: arPosMenFly.x,
        y: arPosMenFly.y,
        duration: 3,
        delay: 0,
      });

      if(!initSoundMenFly){
        playHombreVolando();
        setInitSoundMenFly(true);
      }
    }

    if(initAnimatedMujerBailando){
      gsap.to(".animate-mujer-cayendo", {
        y: arPosMujerBailando.y,
        duration: 3,
        delay: 0,
        rotation: function(i, target, list) {
          return -45;
        },
      });

      gsap.to(".animate-mujer-cayendo", {
        duration: 4,
        delay: 4,
        opacity: 0,
      });
    }

    if(initAnimatedHombreParado){
      gsap.to(".animate-hombre-parado", {
        duration: 3,
        delay: 0,
        opacity: 1,
      });
    }
    if(initAnimatedHombreFuego){
      gsap.to(".animate-hombre-fuego", {
        duration: 3,
        delay: 0,
        opacity: 1,
      });

      if(!initSoundFire){
        playFire();
        setInitSoundFire(true);
      }
    }
  }, [initAnimatedMenFly, initAnimatedMujerBailando, initAnimatedHombreParado, initAnimatedHombreFuego]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateMenFly()}
            {fnAnimateMujerBailando()}
            {fnAnimateHombreParado()}
            {fnAnimateHombreFuego()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page13Comp;
